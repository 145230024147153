import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/functions';
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAMH2uiYNR9t1Wfik4a4GxDVA0TOr_H8bs",
  authDomain: "puzzle-world-e5420.firebaseapp.com",
  databaseURL: "https://puzzle-world-e5420-default-rtdb.firebaseio.com",
  projectId: "puzzle-world-e5420",
  storageBucket: "puzzle-world-e5420.appspot.com",
  messagingSenderId: "670958862949",
  appId: "1:670958862949:web:0e1f1a98d855a8b5e4bc8c",
  measurementId: "G-NQ1KS658DP"
};


firebase.initializeApp(firebaseConfig);
firebase.firestore();

const firebaseFunc = firebase.app().functions();

export { firebase, firebaseFunc };
