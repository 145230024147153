import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import domainReducer from "../../domain/domainReducer";
import authReducer from "../../auth/authReducer";
import operationReducer from "../../operation/operationReducer";;

const rootReducer = combineReducers({
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    domain: domainReducer,
    auth: authReducer,
    operation: operationReducer,

})

export default rootReducer;
