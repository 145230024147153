import { Col, Row } from "antd";
import React, { Fragment } from "react";

const PasswordResetCompletedPage = () => {
  return (
    <Fragment>
      <Row>
        <Col
          offset={6}
          span={12}
          style={{
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          <br />
          <br />
          <div style={{ fontWeight: "bold" }}>
            Password reset completed for Kidszle Account.
          </div>
          <br />
          Let us know if you have further queries, you can contact us at
          support@123KidsAcademy.com.
        </Col>
        <Col span={6} />
      </Row>
    </Fragment>
  );
};

export default PasswordResetCompletedPage;
