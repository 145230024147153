import { Col, Row } from "antd";
import React, { Fragment } from "react";

const EmailVerifiedHomePage = () => {
  return (
    <Fragment>
      <Row>
        <Col
          offset={6}
          span={12}
          style={{
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          <br />
          <br />
          <div style={{ fontWeight: "bold" }}>
            Email verification Completed for kidszle.
          </div>
        </Col>
        <Col span={6} />
      </Row>
    </Fragment>
  );
};

export default EmailVerifiedHomePage;
