import { Button, Col, Form, Input, Row, Spin } from "antd";
import React, { Component } from "react";
import { Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  resetAccountPassword,
  resetPasswordResetData,
} from "../auth/authActions";
import { operationTypeEnum } from "../OperationTypeConstant";
import _ from "lodash";
import PasswordResetFailedModal from "../modals/PasswordResetFailedModal";

class PasswordResetHomePage extends Component {
  state = {
    isLoading: true,
    resetToken: "",
    isUpdating: false,
    isPasswordResetFailedModalVisible: false,
  };

  componentDidMount() {
    let queryParams = new URLSearchParams(this.props.location.search);

    if (queryParams.get("token") !== null) {
      this.setState({
        resetToken: queryParams.get("token"),
        isLoading: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.resetPasswordResetData();
  }

  async componentDidUpdate(prevProps, prevState) {
    const { operation, dbAuth } = this.props;

    if (
      !_.isEqual(prevProps.operation.isProcessing, operation.isProcessing) &&
      !operation.isProcessing
    ) {
      if (
        operation.operationType === operationTypeEnum.NO_OPS ||
        (prevProps.operation.operationType ===
          operationTypeEnum.RESET_ACCOUNT_PASSWORD &&
          operation.operationType === operationTypeEnum.NO_OPS)
      ) {
        if (dbAuth.errorMessages) {
          this.setState({
            isUpdating: false,
            isPasswordResetFailedModalVisible: true,
          });
        } else {
          this.props.history.push("/completed");
        }
      }
    }
  }

  onHandlePasswordResetFormSubmit = (values) => {
    const { resetAccountPassword } = this.props;
    const { resetToken } = this.state;
    this.setState(
      {
        isUpdating: true,
      },
      () => resetAccountPassword(resetToken, values.newPassword)
    );
  };

  onHandlePasswordResetFailedCancelButtonClick = () => {
    this.setState({
      isPasswordResetFailedModalVisible: false,
    });
  };

  onHandlePasswordResetFailedOkButtonClick = () => {
    this.onHandlePasswordResetFailedCancelButtonClick();
  };

  render() {
    const {
      isLoading,
      isUpdating,
      isPasswordResetFailedModalVisible,
    } = this.state;
    const { dbAuth } = this.props;

    if (isLoading) return null;

    return (
      <Fragment>
        <PasswordResetFailedModal
          isVisible={isPasswordResetFailedModalVisible}
          onCancel={this.onHandlePasswordResetFailedCancelButtonClick}
          onOk={this.onHandlePasswordResetFailedOkButtonClick}
          errorMessages={dbAuth.errorMessages}
        />
        <Spin spinning={isUpdating}>
          <Row>
            <Col offset={6} span={12} style={{ marginTop: "24px" }}>
              <Form
                autoComplete="off"
                onFinish={this.onHandlePasswordResetFormSubmit}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Enter your new password for kidszle account
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: "24px", marginTop: "24px" }}
                  >
                    <Form.Item
                      name="newPassword"
                      label="New password"
                      rules={[
                        {
                          required: true,
                          message: "New password is required",
                        },
                      ]}
                    >
                      <Input.Password autoComplete="off" />
                    </Form.Item>
                  </Col>
                  <Col
                    span={24}
                    style={{ fontSize: "24px", textAlign: "center" }}
                  >
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{
                        fontSize: "16px",
                        borderRadius: "4px",
                        height: "35px",
                        textAlign: "center",
                      }}
                    >
                      Reset password
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col span={6} />
          </Row>
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dbAuth: state.auth,
  operation: state.operation,
});

const mapDispatchToProps = {
  resetAccountPassword,
  resetPasswordResetData,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PasswordResetHomePage
);
