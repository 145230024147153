import React, { Fragment } from "react";
import "./App.less";
import { Route, Switch } from "react-router-dom";
import { subdomainTypeEnum } from "./SubdomainTypeConstant";
import { connect } from "react-redux";
import PasswordResetHomePage from "./passwordReset/PasswordResetHomePage";
import PasswordResetCompletedPage from "./passwordReset/PasswordResetCompletedPage";
import EmailVerifiedHomePage from "./EmailVerifiedHomePage";

function App({ domain }) {
  return (
    <Fragment>
      <Switch>
        {domain.subdomainType === subdomainTypeEnum.PASSWORD_RESET && (
          <Route path="/completed" component={PasswordResetCompletedPage} />
        )}
        {domain.subdomainType === subdomainTypeEnum.PASSWORD_RESET && (
          <Route path="/" component={PasswordResetHomePage} />
        )}

        {domain.subdomainType === subdomainTypeEnum.EMAIL_VERIFIED && (
          <Route path="/" component={EmailVerifiedHomePage} />
        )}
      </Switch>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  domain: state.domain,
});

export default connect(mapStateToProps)(App);
